import React from "react"
import { Link, graphql } from "gatsby"
import {
  BlogPostListItem,
  GridBox,
  GridSpacer,
  GridSeparator,
  Text,
  Title,
} from "@thedgbrt/logology-ui-components"
import SEO from "../../components/seo"

const BlogPage = ({ data }) => (
  <>
    <SEO title="Logology Blog - A blog about logo design and brand identity" />
    <GridBox type={5} colSpans={[[2, 6], [4, 7], [5, 11]]} withBgContainer>
      <Title standalone className="mod1">
        A blog about logo design and brand identity
      </Title>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 6], [4, 7], [5, 11]]} withBgContainer>
      <Text standalone italic>
        {data.allPrismicCategory.nodes.map(({ data, uid }) => (
          <>
            <Link to={`/blog/category/${uid}`} style={{ whiteSpace: "pre" }}>
              {data.category}
            </Link>
            &nbsp;&nbsp;{" "}
          </>
        ))}
      </Text>
    </GridBox>
    <GridSpacer desktop={40} mobile={40} tablet={40} type={5} />

    {data.allPrismicBlogPost.nodes.map(({ data, uid }) => (
      <>
        <GridSeparator />
        <BlogPostListItem
          href={`/blog/${uid}`}
          image={data.featured_image.url}
          title={data.title.text}
          key={uid}
        />
      </>
    ))}
  </>
)

export const pageQuery = graphql`
  query {
    allPrismicCategory {
      nodes {
        data {
          category
        }
        uid
      }
    }

    allPrismicBlogPost(sort: { fields: data___publish_date, order: DESC }) {
      nodes {
        uid
        data {
          title {
            text
          }
          featured_image {
            url
          }
          categories {
            category {
              slug
            }
          }
        }
      }
    }
  }
`

export default BlogPage
